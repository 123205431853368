// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './fonts/fonts';
@use './material/material-icons';
//@use '/web/src/styles/fonts/fonts';
//@use '/web/src/styles/material/material-icons';

// Useful tool for generating pallettes https://materialpalettes.com/
$primary: (
  50: #e3f2fd,
  100: #badefb,
  200: #8ecaf8,
  300: #61b6f5,
  400: #3ca6f4,
  500: #0d97f3,
  600: #0789e5,
  700: #0078d2,
  // aa blue
  800: #0066c0,
  900: #0048a1,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$accent: (
  50: #dfecf5,
  100: #b0d1e8,
  // aa light blue
  200: #7cb4db,
  300: #4098cf,
  400: #0085ca,
  500: #0072c3,
  600: #0066b7,
  700: #0056a6,
  800: #004696,
  900: #002c79,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$warn: (
  50: #ffebef,
  100: #ffcdd3,
  200: #f6999b,
  300: #ee7174,
  400: #f94e51,
  500: #ff3935,
  600: #f02f35,
  700: #de232f,
  800: #d11a27,
  900: #c30019,
  // aa danger
  contrast:
    (
      50: black,
      100: black,
      200: black,
      300: black,
      400: black,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: black,
      A200: white,
      A400: white,
      A700: white,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$CCILiteWeb-primary: mat.m2-define-palette(
  $primary,
  900
); // mat.define-palette(mat.$indigo-palette);
$CCILiteWeb-accent: mat.m2-define-palette(
  $accent,
  100
); // mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$CCILiteWeb-warn: mat.m2-define-palette(
  $warn,
  900
); // mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$CCILiteWeb-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $CCILiteWeb-primary,
      accent: $CCILiteWeb-accent,
      warn: $CCILiteWeb-warn,
    ),
  )
);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: #f5f5f7;
}
