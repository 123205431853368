@use 'alert';

/* You can add global styles to this file, and also import other style files */
body {
  font-family: Roboto;
}

.font-medium {
  font-size: 20px;
  line-height: 24px;
}

.font-small {
  font-size: 16px;
  line-height: 24px;
}

.font-weight-big {
  font-weight: 500;
}

.font-italic {
  font-style: italic;
}

.overflow-scroll {
  overflow: visible !important ;
}

.align-self-center {
  align-self: center;
}

.primary-snackbar {
  background-color: white;
  color: black;

  .mat-simple-snackbar-action {
    color: black;
  }
}
