@use 'sass:map';
$crewcheckin-header-content-max-width: 1040px;
$crewcheckin-icon-color-white: #ffffff;
$crewcheckin-header-background-color: #002849;
$crewcheckin-header-nav-bar-background-color: #ffffff;
$crewcheckin-header-nav-bar-shadow: 0px 2px 4px 0px #2b2b2b33;
$crewcheckin-header-nav-bar-selected: 4px solid #085abd;
$crewcheckin-header-nav-bar-hover-color: #085abd;
$crewcheckin-header-nav-text-color: #1b1b1b;
$crewcheckin-input-bkg-color: #f5f5f7;
$crewcheckin-search-result-icon-color: #929292;
$crewcheckin-sequence-text-color: #929292;
$crewcheckin-card-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.16);
$crewcheckin-sequence-signin-text-color: #1b1b1b;
$crewcheckin-sequence-top-devider: #e4e9ec;
$signin-sequence-background-color: rgba(8, 90, 189, 0.08);
$signin-sequence-checkbox-color: #028810;
$crewcheckin-border-color: #f5f5f7;
$border-line-color: #f7f7f7;
$aa-loader-background-color: rgba(0, 0, 0, 0.24);

$colors: (
  1b1b1b: #1b1b1b,
  f5f5f7: #f5f5f7,
  ffffff: #ffffff,
  929292: #929292,
  028810: #028810,
  085abd: #085abd,
);

$font-sizes: (
  14: 14px,
  16: 16px,
  18: 18px,
  20: 20px,
);
/**
* Get font size
*/
@mixin font-size($size) {
  font-size: map.get($font-sizes, $size);
}
/**
* Get color
*/
@mixin get-color($color) {
  color: map.get($colors, $color);
}

/**
* Font - Roboto Condensed
*/
@mixin font-family-roboto-condensed {
  font-family: 'Roboto Condensed';
}
