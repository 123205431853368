/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  /* src: url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.eot'); */ /* IE9 Compat Modes */
  src: local(''),
    /* ('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.eot?#iefix') format('embedded-opentype'), */
      /* IE6-IE8 */
      url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.svg#RobotoCondensed')
      format('svg'); /* Legacy iOS */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  /* src: url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.eot'); */ /* IE9 Compat Modes */
  src: local(''),
    /* url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.eot?#iefix') format('embedded-opentype'), */
      /* IE6-IE8 */
      url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.svg#RobotoMono')
      format('svg'); /* Legacy iOS */
}
//TODO look into why we are using latin and see if there is a fix
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(latin-ext-300.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(latin-300.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(latin-ext-400.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(latin-400.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(latin-ext-500.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(latin-500.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
