@use 'variables';
/**
  * Info color theme
  */
.info {
  background: variables.$signin-sequence-background-color;
  padding: 8px 0;
  .mat-icon {
    @include variables.get-color(085abd);
  }
  .alert-group {
    .alert-text {
      @include variables.get-color(085abd);
    }
  }
}
/**
  * Info color theme
  */
.success {
  background: unset;
  .mat-icon {
    @include variables.get-color(028810);
  }
  .alert-group {
    .alert-text {
      @include variables.get-color(1b1b1b);
    }
  }
}
/**
  * Alert box
  */
.alert-box {
  align-items: center;
  display: flex;
  border-radius: 12px;
  margin-top: 8px;
  .alert-group {
    display: flex;
    align-items: center;
    flex-grow: 1;
    .mat-icon {
      padding-left: 18px;
      padding-right: 20px;
    }
    .alert-text {
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      @include variables.font-size(16);
    }
  }
  .alert-end-icon {
    display: flex;
    padding-right: 0.7rem;
  }
}
